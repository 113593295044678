/*

TemplateMo 558 Klassy Cafe

https://templatemo.com/tm-558-klassy-cafe

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");




/* 
---------------------------------------------
reset
--------------------------------------------- 
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

img{
  object-fit: scale-down;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}


header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: "Avenir";
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 25px;
  color: #2a2a2a;
}


html {
  position: relative;
}
body {
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

#logo2 {
  width: 180px;
  height: 100%;
  max-height: 94px;
  transform: translateZ(0);
}


#logo3 {
  width: 120px;
  max-height: 144px;
  height: 100%;
  transform: translateZ(0);
}


footer {
  position:relative;
  clear:both;
  width:100%;
  align-self: flex-end;
  bottom:0;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: "Avenir";;
}

::selection {
  background: #A1D5C7;
  color: #fff;
}

::-moz-selection {
  background: #A1D5C7;
  color: #fff;
}

@media (max-width: 991px) {
  html, body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.main-white-button a {
  font-size: 13px;
  color: #A1D5C7;
  background-color: #fff;
  padding: 12px 25px;
  display: inline-block;
  border-radius: 3px;
  font-weight: 600;
  transition: all .3s;
}

.main-white-button a:hover {
  opacity: 0.9;
}

.main-text-button a {
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  transition: all .3s;
}

.main-text-button a:hover {
  opacity: 0.9;
}

.section-heading h6 {
  position: relative;
  font-size: 28px;
  color: #A1D5C7;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-left: 40px;
}

.section-heading h6:before {
  width: 25px;
  height: 2px;
  content: '';
  position: absolute;
  left: 0;
  top: 14px;
  background-color: #A1D5C7;
}

.section-heading h2 {
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 25px; 
  font-size: 30px;
  font-weight: 900;
  color: #2a2a2a;
}






/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #F4F6F6;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.5s;
  color: #17203D;
}

/* Media */

@media (max-width: 767px) {
  #topbar {
  height: 40px;
  font-size: 10px;
  font-size: 1.85ƒvw;
  font-weight: bold;
  transition: all 0.5s;
  color: #17203D;
  }
  #topbar .contact-info{
    margin-top: 10px;
  }
  
}

#topbar.topbar-scrolled {
  top: -40px;
}

#topbar .contact-info a {
  line-height: 2;
  color: #17203D;
  transition: 0.3s;
}

#topbar .contact-info a:hover {
  text-decoration: underline;
}

#topbar .contact-info i {
  padding-right: 6px;
  margin-left: 24px;
}

#topbar .contact-info i:first-child {
  margin-left: 0;
}

#topbar .social-links a {
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

#topbar .social-links a:hover {
  color: #17203D;
}




/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.background-header {
    background-color: #fff;
    height: 105px!important;
    position: fixed!important;
    top: 0px;
    left: 0px;
    right: 0px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
  }
  
  .background-header .logo,
  .background-header .main-nav .nav li a {
    color: #1e1e1e!important; 
  }
  
  .background-header .main-nav .nav li:hover a {
    color: #A1D5C7!important;
  }
  
  .background-header .nav li a.active {
    color: #A1D5C7!important;
  }
  
  .active a{

      color: #A1D5C7!important;
  }

  
  .header-area {
    margin-top: 40px;
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    height: 100px;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
  }
  
  .header-area .main-nav {
    min-height: 80px;
    background: transparent;
  }
  
  .header-area .main-nav .logo {
    line-height: 100px;
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    float: left;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  
  .background-header .main-nav .logo {
    line-height: 105px;
  }
  
  .background-header .nav {
    margin-top: 20px !important;
  }
  
  .header-area .main-nav .nav {
    float: right;
    margin-top: 30px;
    margin-right: 0px;
    background-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 999;
  }
  
  .header-area .main-nav .nav li {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .header-area .main-nav .nav li:last-child {
    padding-right: 0px;
  }
  
  .header-area .main-nav .nav li a {
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: #2a2a2a;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    height: 40px;
    line-height: 40px;
    border: transparent;
    letter-spacing: 1px;
  }
  
  .header-area .main-nav .nav li a {
    color: #2a2a2a;
  }
  
  .header-area .main-nav .nav li:hover a,
  .header-area .main-nav .nav li a.active {
    color: #A1D5C7!important;
    font-weight: bold;
    font-weight: 900;
    font-size: 18px;
  }
  
  .background-header .main-nav .nav li:hover a,
  .background-header .main-nav .nav li a.active {
    color: #A1D5C7!important;
    opacity: 1;
  }

  .nav-blog {
    color: #2a2a2a;
  }
  .nav-blog.active {
    color: #A1D5C7!important;
    opacity: 1;
  }

  
  .current {
    color: #A1D5C7!important;
    opacity: 1;
  }
  
  .header-area .main-nav .nav li.submenu {
    position: relative;
    padding-right: 38px;
  }



  
  .header-area .main-nav .nav li.submenu:after {
    content: "\f107";
    font-family: FontAwesome;
    font-size: 12px;
    font-weight: 600;
    color: #2a2a2a;
    position: absolute;
    right: 18px;
    top: 12px;
  }
  
  .background-header .main-nav .nav li.submenu:after {
    color: #2a2a2a;
  }
  
  .header-area .main-nav .nav li.submenu ul {
    position: absolute;
    width: 240px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
    top: 50px;
    opacity: 0;
    transform: translateY(+2em);
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  }
  
  .header-area .main-nav .nav li.submenu ul li {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .header-area .main-nav .nav li.submenu ul li a {
    opacity: 1;
    display: block;
    background: #f7f7f7;
    color: #2a2a2a!important;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    font-size: 13px;
    font-weight: 600;
    border-bottom: 1px solid #eee;
  }
  
  .header-area .main-nav .nav li.submenu ul li a:hover {
    background: #fff;
    color: #A1D5C7!important;
    padding-left: 25px;
    font-weight: 900;
  }
  
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 3px;
  }
  
  .header-area .main-nav .nav li.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }
  
  .header-area .main-nav .menu-trigger {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 35px;
    width: 32px;
    height: 40px;
    text-indent: -9999em;
    z-index: 99;
    right: 40px;
    display: none;
  }
  
  .background-header .main-nav .menu-trigger {
    top: 23px;
  }
  
  .header-area .main-nav .menu-trigger span,
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #1e1e1e;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
  }
  
  .background-header .main-nav .menu-trigger span,
  .background-header .main-nav .menu-trigger span:before,
  .background-header .main-nav .menu-trigger span:after {
    background-color: #1e1e1e;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #1e1e1e;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
    width: 75%;
  }
  
  .background-header .main-nav .menu-trigger span:before,
  .background-header .main-nav .menu-trigger span:after {
    background-color: #1e1e1e;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    content: "";
  }
  
  .header-area .main-nav .menu-trigger span {
    top: 16px;
  }
  
  .header-area .main-nav .menu-trigger span:before {
    -moz-transform-origin: 33% 100%;
    -ms-transform-origin: 33% 100%;
    -webkit-transform-origin: 33% 100%;
    transform-origin: 33% 100%;
    top: -10px;
    z-index: 10;
  }
  
  .header-area .main-nav .menu-trigger span:after {
    -moz-transform-origin: 33% 0;
    -ms-transform-origin: 33% 0;
    -webkit-transform-origin: 33% 0;
    transform-origin: 33% 0;
    top: 10px;
  }
  
  .header-area .main-nav .menu-trigger.active span,
  .header-area .main-nav .menu-trigger.active span:before,
  .header-area .main-nav .menu-trigger.active span:after {
    background-color: transparent;
    width: 100%;
  }
  
  .header-area .main-nav .menu-trigger.active span:before {
    -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
    -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
    -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
    transform: translateY(6px) translateX(1px) rotate(45deg);
    background-color: #1e1e1e;
  }
  
  .background-header .main-nav .menu-trigger.active span:before {
    background-color: #1e1e1e;
  }
  
  .header-area .main-nav .menu-trigger.active span:after {
    -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    transform: translateY(-6px) translateX(1px) rotate(-45deg);
    background-color: #1e1e1e;
  }
  
  .background-header .main-nav .menu-trigger.active span:after {
    background-color: #1e1e1e;
  }
  
  .header-area.header-sticky {
    min-height: 80px;
    border-bottom:0.001px solid rgb(233, 240, 237);
  }
  
  .header-area .nav {
    margin-top: 30px;
  }
  
  .header-area.header-sticky .nav li a.active {
    color: #A1D5C7;
  }
  
 
  
  @media (max-width: 1199px) {
    .header-area .main-nav .logo {
      color: #1e1e1e;
    }
    .header-area.header-sticky .nav li a:hover,
    .header-area.header-sticky .nav li a.active {
      color: #A1D5C7!important;
      opacity: 1;
    }
    .header-area.header-sticky .nav li.search-icon a {
      width: 100%;
    }
    .header-area {
      background-color: #fff;
      padding: 0px 15px;
      height: 100px;
      box-shadow: none;
      text-align: center;
    }
    .header-area .container {
      padding: 0px;
    }
    .header-area .logo {
      margin-left: 30px;
    }
    .header-area .menu-trigger {
      display: block !important;
    }
    .header-area .main-nav {
      overflow: hidden;
    }
    .header-area .main-nav .nav {
      float: none;
      width: 100%;
      display: none;
      -webkit-transition: all 0s ease 0s;
      -moz-transition: all 0s ease 0s;
      -o-transition: all 0s ease 0s;
      transition: all 0s ease 0s;
      margin-left: 0px;
    }
    .header-area .main-nav .nav li:first-child {
      border-top: 1px solid #eee;
    }
    .header-area.header-sticky .nav {
      margin-top: 105px !important;
    }
    .header-area .main-nav .nav li {
      width: 100%;
      background: #fff;
      border-bottom: 1px solid #eee;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .header-area .main-nav .nav li a {
      height: 40px !important;
      text-align: center;
      line-height: 50px !important;
      padding: 0px !important;
      border: none !important;
      background: #f7f7f7 !important;
      color: #191a20 !important;
    }
    .header-area .main-nav .nav li a:hover {
      text-align: center;
      background: #eee !important;
      color: #A1D5C7!important;
    }
    .header-area .main-nav .nav li.submenu ul{
      display: block;
      height: auto;
      max-height: 150px;
      overflow-y: auto;
    }

  

    .header-area .main-nav .nav li.submenu ul {
      position: relative;
      visibility: inherit;
      opacity: 1;
      z-index: 1;
      transform: translateY(0%);
      transition-delay: 0s, 0s, 0.3s;
      top: 0px;
      width: 100%;
      box-shadow: none;
      height: 0px;
    }
    .header-area .main-nav .nav li.submenu ul li a {
      font-size: 14px;
      font-weight: 500;
    }
    .header-area .main-nav .nav li.submenu ul li a:hover:before {
      width: 0px;
    }
    .header-area .main-nav .nav li.submenu ul.active {
      height: auto !important;
    }
    .header-area .main-nav .nav li.submenu:after {
      color: #3B566E;
      right: 25px;
      font-size: 14px;
      top: 15px;
    }
    .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
      height: 0px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-area .main-nav .nav {
      display: flex !important;
    }
  }



/* 
---------------------------------------------
Welcome
--------------------------------------------- 
*/

.welcome{ 
  margin-top:136px;
  margin-bottom: 20px;
  width:100%;
  height:75vh;
  min-height: 400px;
  position: static;
  background: #eeede5;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome .welcomeconoce .welco-conoce {
  margin-top: 170px;
  align-content: center;
  text-align: center;
}

.welcome .welcomeconoce h1{
  font-size:45px;
  font-weight:700;
  color:#17203D;
  font-family:"Avenir";
  line-height:1.2;
  text-transform:capitalize;
  margin-bottom:30px;
  display:block;
}
@media only screen and (min-width: 992px) and (max-width: 1400px){

  .welcome .welcomeconoce{
    margin-bottom: 70px;
    margin-top: 10px;
  }

  .welcome .welcomeconoce h1{
    font-size:32px;
    line-height:1.2;
  }
  .welcome .welcomeconoce .welco-conoce {
    margin-top: 95px;
  }

  .welcome .welcomeconoce .welco-video a.videoconocenos {
    margin-left: 58px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
  .welcome .welcomeconoce h1{
    font-size:28px;
    line-height:1.2;
  }
  .welcome .welcomeconoce .welco-conoce {
    margin-top: 25px;
  }
}
@media (max-width: 767px){
  .welcome .welcomeconoce h1{
    font-size:20px;
    line-height:1.3;
  }

  .welcome .welcomeconoce .welco-conoce {
    margin-top: 25px;
  }
}
.welcome .welcomeconoce .welco-conoce p{
  font-size:30px;
  line-height:30px;
  margin-bottom:39px;
  color:#6D7280;
  margin-bottom:25px;
  padding-right:49px;
  text-transform:capitalize;
}

.welcome .welcomeconoce .welco-video p{
  font-size:20px;
  line-height:30px;
  margin-bottom:39px;
  color:#17203D;
  margin-bottom:25px;
  padding-right:49px;
  text-transform:capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1400px){
  .welcome .welcomeconoce .welco-conoce p{
    padding-right:0px;
    font-size:20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .welcome .welcomeconoce .welco-conoce p{
    padding-right:0px;
    font-size:18px;
  }
}
@media (max-width: 767px){
  .welcome .welcomeconoce .welco-conoce p{
    padding-right:0px;
    font-size:18px;
  }
  .welcome .welcomeconoce .welco-video p{
    padding-right:0px;
    font-size:18px;
  }
}

.welcome .welco-video{
  margin-top: 18%;
  align-content: center;
  text-align: center;
}

.welcome .welcomeimagenes{
  margin-top: 30px;
  align-content: left;
}

.welcome .welcomeimagenes2 {
  margin-top: 30px;
  align-content: left;
}

@media (max-height: 800px){
  .welcome .welcomeimagenes{
    margin-top: 80px;
  }
  .welcome .welcomeimagenes2{
    margin-top: 80px;
  }
}



.welcome .welcomeconoce .welco-video img{
  margin-left: 20px;
}

@media (max-width: 767px){
  .welcome .welcomeconoce .welco-video .button {
    background-color: #E15539;
    border: none;
    color: white;
    padding: 7px;
    width: 110px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-family:"Avenir";;
    margin: 4px 2px;
    border-radius: 10px;
  }

  
}


.welcome .welcomeconoce .welco-video a.videoconocenos:hover {
  color: #E15539;
}



.welcome .welcomeconoce .welco-video {
  margin-top: 11%;
}


@media only screen and (min-width: 992px) and (max-width: 1400px){
  .welcome .welcomeconoce .welco-video {
    margin-top: 18%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .welcome .welcomeconoce .welco-video {
    margin-top: 20%;
  }
}

@media (max-width: 767px){
  .welcome .welcomeconoce .welco-video {
    margin-top: 30%;
  }
}


.welcomeimagenes .img{
  max-width: 12rem;
  box-shadow: 0px 5px 5px 0px #adb5bd;
}

.img-fluid-3 {
  align-content: left center;
  margin-right: 90%;
  object-fit: cover;
  width: 8vw;
  height: 8vw;
}

.img-fluid-2 {
  align-content: left center;
  margin-left: 20%;
  object-fit: cover;
  width: 10vw;
  height: 10vw;
}

.rounded-circle {
  border-radius: 50% !important;
}

@keyframes img-ani {
  from{opacity:0;}
  to{opacity: 1;}
}

.mb-3 {
  margin-top: 4%;
  margin-bottom: 1rem !important;
  border:2px solid #666;
  animation-name:img-ani;
  animation-duration: 4s;   
  animation-timing-function: ease-in;
}

.mb-2 {
  margin-top: 2%;
  margin-bottom: 1rem !important;
  border:2px solid #666;
}

.img-fluid-1 {
  align-content: left center;
  margin-left: 18%;
  object-fit: cover;
  width: 18vw;
  height: 18vw;
}

.mb-1 {
  margin-bottom: 1rem !important;
  border:4px solid #666;
}



/* Boton de play */



.video-play-button {
  position: relative;
  z-index: 10;
  margin-top: -10px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #A1D5C7;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #A1D5C7;
  border-radius: 50%;
  transition: all 400ms;
}

.video-play-button:hover:after {
  background-color: darken(#A1D5C7, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}



.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.80);
  opacity: 0;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 8%;
  right: 8%;
  font-size: 66px;
  line-height: 4;
  font-weight: 900;
  color: #E15539;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
}


@media only screen and (min-width: 992px) and (max-width: 1400px){
  .video-overlay-close {
    position: absolute;
  z-index: 1000;
  top: 12%;
  right: 12%;
  font-size: 56px;
  line-height: 4;
  font-weight: 900;
  color: #E15539;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 14%;
  right: 10%;
  font-size: 56px;
  line-height: 4;
  font-weight: 900;
  color: #E15539;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
  }
}

@media (max-width: 767px){
  .video-overlay-close {
    position: absolute;
  z-index: 1000;
  top: 10%;
  right: 6%;
  font-size: 48px;
  line-height: 4;
  font-weight: 900;
  color: #E15539;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
  }
}



.video-overlay-close:hover {
  color: #A1D5C7;
}

.video-overlay iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* width: 90%; */
  /* height: auto; */
  box-shadow: 0 0 12px rgba(0,0,0,0.75);
}

@media (max-width: 767px){
  .video-overlay iframe {
    position: absolute;
    max-width: 80%;
    max-height: 30%;
    top: 57%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    /* width: 90%; */
    /* height: auto; */
    box-shadow: 0 0 12px rgba(0,0,0,0.75);
  }
}

/*
.welcome .welcomeconoce .welco-video h2::before{
  position:absolute;
  margin-top: 2%;
  content:"";
  width:300px;
  height:5px;
  background:#B5C2C0;
  border-radius:2px;
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  -ms-border-radius:2px;
  -o-border-radius:2px;
}
*/



/*
---------------------------------------------
about
---------------------------------------------
*/

#about2 {
  margin-top: 100px;
  padding: 100px 0px 0px 0px;
  position: relative;
  z-index: 10;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

#about2 .section-heading h2 {
  padding-right: 120px;
}

#about2 .left-text-content p {
  margin-bottom: 25px;
}



/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  border: 4px solid #F4F6F6;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f1f6fe;
  border-radius: 4px;
  border: 1px solid #F4F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.services .icon-box .icon{
  color: #17203D;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4{
  color: #17203D;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
}

.services .icon-box:hover h4, .services .icon-box:hover .icon {
  color: #A1D5C7!important;
}


.services .icon-box:hover .icon {
  border-color: #A1D5C7;
}

/* Section */

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f6f9fe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #A1D5C7;
  color: #17203D;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
}

.section-title h3 span {
  color: #A1D5C7;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1400px){
  .section-title h3 {
    margin: 15px 0 0 0;
    font-size: 30px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .section-title h3 {
    margin: 15px 0 0 0;
    font-size: 28px;
    font-weight: 700;
  }
}

@media (max-width: 767px){
  .section-title h3 {
    margin: 15px 0 0 0;
    font-size: 20px;
    font-weight: 700;
  }
}

.services .icon-box .h4_largo{
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 22px;
}




/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/


.section-bg {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.about img {
  display:block;
  margin:auto;
  max-height: 620px;
  width: auto;
  transform: translateZ(0);
}

.about .p_foto{
  font-style: oblique;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #17203D;
}

@media (max-width: 767px){
  .about .p_foto{
    font-style: oblique;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: #17203D;
  }
}

.about .content{
  margin-bottom: 30px;
}


.about .content h3 {
  text-align: left;
  font-weight: 600;
  font-size: 26px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.about .content p {
  text-align: left;
  font-weight: 400;
  font-size: 18px;
}


@media (max-width: 767px){
  .about .content p {
    text-align: left;
  }
  .about .content h3 {
    text-align: left;
  }
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.about .content ul li:first-child {
  margin-top: 35px;
}

.about .content ul i{
  background: #fff;
  box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
  font-size: 24px;
  padding: 20px;
  margin-right: 25px;
  color: #A1D5C7;
  border-radius: 50px;
}

.about .content ul h4 {
  margin-top: 24px;
  font-size: 18px;
  color: #17203D;
}

.about .content ul p {
  font-size: 16px;
}

.about .content p:last-child {
  margin-bottom: 0;
}



/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 42px;
  color: #A1D5C7;
  border-radius: 50%;
  padding: 8px;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contact button{
  margin-top: 3%;
  font-size: 20px;
  background: #17203D;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}


/* 
---------------------------------------------
footer
--------------------------------------------- 
*/

footer {
    margin-top: 70px;
    padding: 50px 0px;
    background-color: #A1D5C7;
  }
  
  footer .left-text-content p {
    margin-top: 30px;
    color: #fff;
    font-size: 14px;
    text-align: right;
  }
  
  footer .right-text-content p {
    color: #fff;
    font-size: 14px;
    margin-right: 15px;
    text-transform: uppercase;
  }
  
  
  footer .right-text-content {
    text-align: left;
    margin-top: 55px;
  }
  
  footer .logo {
    margin-top: 2em;
    text-align: center;
  }
  
  footer .right-text-content ul li {
    display: inline-block;
    margin-left: 15px;
  }
  footer .right-text-content ul li:first-child {
    margin-left: 0px;
  }
  
  
  footer .right-text-content ul li a {
    width: 44px;
    height: 44px;
    display: inline-block;
    text-align: center;
    line-height: 44px;
    font-size: 20px;
    background-color: #fff;
    border-radius: 50%;
    color: #A1D5C7;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
  }
  
  /* Color icones redes sociales*/
  footer .right-text-content ul li a.facebook:hover {
    color: #3b5998;
  }
  footer .right-text-content ul li a.youtube:hover {
    color: #c4302b;
  }
  footer .right-text-content ul li a.linkedin:hover {
    color: #0e76a8;
  }
  footer .right-text-content ul li a.twitter:hover {
    color: #000000 ;
  }

  footer p a {
    margin-top: 5px;
    color: #fff;
    font-size: 14px;
    text-align: right;
  }

  footer p a:hover {
    color: #1e1e1e;;
  }

  footer .avisolegal{
    height: 10px;
    margin-top: 30px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.5s; 
  }

  footer .avisolegal a{
    color: #fff;
    font-size: 18px;
    text-align: center;
  }

  footer .avisolegal a:hover{
    color: #1e1e1e;
  }
  
/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/

@media (max-width: 992px) {
    html, body {
      position: relative;
    }
    #top {
      padding-top: 100px;
      height: auto;
    }

    .section-heading h6 {
      position: relative;
      font-size: 20px;
      color: #A1D5C7;
      font-weight: 500;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-left: 40px;
    }

    .section-heading h6:before {
      width: 25px;
      height: 2px;
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
      background-color: #A1D5C7;
    }

    .header-area .main-nav .nav li.submenu:after {
      right: 3px;
    }
    .header-area .main-nav .nav li.submenu {
      padding-right: 15px;
    }
    .header-area .main-nav .nav li {
      padding-right: 5px;
      padding-left: 5px;
    }
    .header-area .main-nav .nav li a {
      font-size: 14px;
      letter-spacing: 0px;
    }
    #top .left-content {
      text-align: center;
      padding: 25% 0px;
    }
    #about .right-content {
      margin-left: 0px;
      margin-top: 30px;
      margin-bottom: 60px;
    }
   

    footer .right-text-content {
      text-align: center;
    }
    footer .left-text-content p {
      text-align: center;
    }
    footer {
      position:relative;
      bottom:0;
      clear:both;
      width:100%;
      align-self: flex-end;
    }

    footer .avisolegal a{
      color: #fff;
      font-size: 15px;
      text-align: center;
    }

  }
  
  
  
  
  
  #preloader {
    overflow: hidden;
    background-color: #A1D5C7;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 99999;
    color: #fff;
  }
  
  #preloader .jumper {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    margin: auto;
    width: 50px;
    height: 50px;
  }
  
  #preloader .jumper > div {
    background-color: #fff;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    opacity: 0;
    width: 50px;
    height: 50px;
    -webkit-animation: jumper 1s 0s linear infinite;
    animation: jumper 1s 0s linear infinite;
  }
  
  #preloader .jumper > div:nth-child(2) {
    -webkit-animation-delay: 0.33333s;
    animation-delay: 0.33333s;
  }
  
  #preloader .jumper > div:nth-child(3) {
    -webkit-animation-delay: 0.66666s;
    animation-delay: 0.66666s;
  }
  
  @-webkit-keyframes jumper {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    5% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes jumper {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    5% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
/* 
---------------------------------------------
Pagina Contact
--------------------------------------------- 
*/


  .pcontacta{ 
    margin-top:136px;
    margin-bottom: 20px;
    width:100%;
    height:60vh;
    min-height: 400px;
    position: static;
    background: #f5f5f8fd;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .pcontacta .welcomeconoce .welco-conoce {
    margin-top: 80px;
    align-content: center;
    text-align: center;
    margin: auto;
	  display: table;
  }
  
  .pcontacta .welcomeconoce h1{
    font-size:58px;
    font-weight:700;
    color:#17203D;
    font-family:"Avenir";;
    line-height:1.2;
    text-transform:uppercase;
    margin-bottom:30px;
    display:block;
  }

  .pcontacta h1 span {
    color: #A1D5C7;
  }
  @media only screen and (min-width: 992px) and (max-width: 1400px){
  
    .pcontacta .welcomeconoce{
      margin-bottom: 70px;
      margin-top: 10px;
    }
  
    .pcontacta .welcomeconoce h1{
      font-size:32px;
      line-height:1.2;
    }
    .pcontacta .welcomeconoce .welco-conoce {
      margin-top: 95px;
    }
  
    .pcontacta .welcomeconoce .welco-video a.videoconocenos {
      margin-left: 58px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px){
    .pcontacta .welcomeconoce h1{
      font-size:28px;
      line-height:1.2;
    }
    .pcontacta .welcomeconoce .welco-conoce {
      margin-top: 25px;
    }
  }
  @media (max-width: 767px){
    .pcontacta .welcomeconoce h1{
      font-size:20px;
      line-height:1.3;
    }
  
    .pcontacta .welcomeconoce .welco-conoce {
      margin-top: 25px;
    }
  }
  
  .pcontacta .welcomeconoce .welco-conoce h2{
    position: relative; 
    font-size:30px;
    line-height:30px;
    margin-bottom:39px;
    color:#6D7280;
    margin-bottom:25px;
    padding-right:49px;
    text-transform:capitalize;
  }

  .pcontacta .welcomeconoce .welco-conoce h2 span {
    position:absolute;
    right:0;
    width:33%;
    background: #f5f5f8fd;
    border-left: 1px solid #000;
    animation: escribir 5s steps(30) alternate;
}

@keyframes escribir {
    from { width: 78% }
    to { width:34% }
}

  @media only screen and (min-width: 992px) and (max-width: 1400px){
    .pcontacta .welcomeconoce .welco-conoce h2{
      padding-right:0px;
      font-size:20px;
    }
    .pcontacta .welcomeconoce .welco-conoce h2 span {
      position:absolute;
      right:0;
      width:25%;
  }
  @keyframes escribir {
    from { width: 78% }
    to { width:25% }
  }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px){
    .pcontacta .welcomeconoce .welco-conoce h2{
      padding-right:0px;
      font-size:18px;
    }
    .pcontacta .welcomeconoce .welco-conoce h2 span {
      position:absolute;
      right:0;
      width:22%;
  }
  @keyframes escribir {
    from { width: 78% }
    to { width:22% }
  }
  }
  @media (max-width: 767px){
    .pcontacta .welcomeconoce .welco-conoce h2{
      padding-right:0px;
      font-size:18px;
    }
    .pcontacta .welcomeconoce .welco-video h2{
      padding-right:0px;
      font-size:18px;
    }
    .pcontacta .welcomeconoce .welco-conoce h2 span {
      position:absolute;
      right:0;
      width:20%;
  }
  @keyframes escribir {
    from { width: 78% }
    to { width:18% }
  }
  }

  .pcontacta .welcomeconoce .welco-conoce p{
    font-size:20px;
    line-height:30px;
    margin-bottom:39px;
    color:#17203D;
    margin-bottom:25px;
    padding-right:49px;
    font-family:"Avenir";;
    line-height:1.8;
  }

  .pcontacta .welcomeconoce .welco-conoce p span{
    font-weight: bold;
  }

  @media only screen and (min-width: 992px) and (max-width: 1400px){
    .pcontacta .welcomeconoce .welco-conoce p{
      padding-right:0px;
      font-size:16px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px){
    .pcontacta .welcomeconoce .welco-conoce p{
      padding-right:0px;
      font-size:14px;
    }
  }
  @media (max-width: 767px){
    .pcontacta .welcomeconoce .welco-conoce p{
      padding-right:0px;
      font-size:14px;
    }
  }



  .pcontacta .welcomeimagenes {
    text-align: left;
    margin-top: 2vh;
    margin-bottom: auto;
  }


  .pcontacta .img-fluid-4 {
    margin-left: -15%;
    object-fit: cover;
    width: 18vw;
    height: 15vw;
  }

  @media only screen and (min-width: 992px) and (max-width: 1400px){
    .pcontacta .welcomeimagenes {
      text-align: left;
      margin-top: 7.5vw;
    }
    .pcontacta .img-fluid-4 {
      margin-left: -15%;
      object-fit: cover;
      width: 22vw;
      height: 18vw;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px){
    .pcontacta .welcomeimagenes {
      text-align: left;
      margin-top: 12vw;
    }
  }
  @media (max-width: 767px){
    .pcontacta .welcomeimagenes {
      text-align: left;
      margin-top: 12vw;
    }
  }


  /*--------------------------------------------------------------
# Contact_2
--------------------------------------------------------------*/
.contact_2 .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}

.contact_2 .info-box i {
  font-size: 42px;
  color: #A1D5C7;
  border-radius: 50%;
  padding: 8px;
}

.contact_2 .info-box h3 {
  font-size: 19px;
  color: #777777;
  font-weight: 600;
  margin: 10px 0;
}

.contact_2 .info-box h4 {
  font-size: 17px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact_2 .info-box h4 {
  font-size: 20px;
  color: #17203D;
  font-weight: 600;
  margin: 10px 0;
}

.contact_2 .info-box p {
  padding: 0;
  line-height: 24px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}


@media only screen and (min-width: 992px) and (max-width: 1400px){
  .contact_2 .info-box h3 {
    font-size: 14px;
  }
  .contact_2 .info-box p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .contact_2 .info-box h3 {
    font-size: 20px;
  }
}
@media (max-width: 767px){
  .contact_2 .info-box h3 {
    font-size: 20px;
  }
}





@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contact_2 a {
  line-height: 2;
  color: #17203D;
  transition: 0.3s;
}

.contact_2  a:hover {
  text-decoration: underline;
}


.form_group {
  position: relative;
  padding: 8px 0 0;
  width: 100%;
}

.form_field {
  font-family: inherit;
  width: 35%;
  border: 0;
  border-bottom: 2px solid #A1D5C7;
  outline: 0;
  font-size: 0.9rem;
  color: #17203D;
  font-weight: 500;
  padding: 3px 0;
  background: transparent;
  transition: border-color 0.2s;
  margin-right: 5%;
}

.form_field2{
  height: 15px;
  display: inline-block;
  width: 15px;
  background: #eee;
  margin-top: 4%;
  margin-right: 2%;
}

.form_check_label {
  padding: 0;
  line-height: 24px;
  font-weight: 500;
  font-size: 7.2px;
  margin-bottom: 2px;
  margin-right: 6%;
}

.form_field3 {
  font-family: inherit;
  width: 25%;
  padding: 3px 0;
}



.form_label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: gray;
}

input[type=button],
      input[type=submit] {
        background-color: #A1D5C7;
        border: none;
        color: #fff;
        padding: 15px 30px;
        text-decoration: none;
        margin: 4px 2px;
        cursor: pointer;
}


      /* Imagenes section */



 .showcase .showcase-text {
   padding: 3rem;
}
 .showcase .showcase-img {
   min-height: 30rem;
   background-size: cover;
  }
@media (min-width: 768px) {
  .showcase .showcase-text {
      padding: 7rem;
  }
}
.text-white {
  color: #fff !important;
}

.p-0 {
  padding: 0 !important;
}

.g-0,
 .gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
 .gy-0 {
  --bs-gutter-y: 0;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.showcase-text h2{
  text-align: center;
}

.showcase-text span{
  font-weight: bold;
}

.mb-2{
border: 0em;
text-align: center;
}

.mb-1{
  border: 0em;
  text-align: center;
  }

@media only screen and (min-width: 768px) and (max-width: 991px){
  .mb-0 {
    margin-bottom: 0 !important;
    text-align: center;
    align-items: center;
  }
}
@media (max-width: 767px){
  .mb-0 {
    margin-bottom: 0 !important;
    text-align: center;
    align-items: center;
  }
}


.center-text-content {
  text-align: center;
  margin-top: 45px;
  margin-bottom: 25px;
}



.center-text-content ul li {
  display: inline-block;
  margin-left: 15px;
}
.center-text-content ul li:first-child {
  margin-left: 0px;
}

/* Color icones redes sociales*/
.center-text-content ul li a.facebook {
  width: 56px;
  height: 56px;
  display: inline-block;
  text-align: center;
  line-height: 55px;
  font-size: 34px;
  background-color: #3b5998;
  border-radius: 50%;
  color: #fff;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.center-text-content ul li a.youtube{
  width: 56px;
  height: 56px;
  display: inline-block;
  text-align: center;
  line-height: 55px;
  font-size: 30px;
  background-color: #c4302b;
  border-radius: 50%;
  color: #fff;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.center-text-content ul li a.linkedin{
  width: 56px;
  height: 56px;
  display: inline-block;
  text-align: center;
  line-height: 55px;
  font-size: 30px;
  background-color: #0e76a8;
  border-radius: 50%;
  color: #fff;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}


.center-text-content ul li a.twitter{
  width: 56px;
  height: 56px;
  display: inline-block;
  text-align: center;
  line-height: 55px;
  font-size: 30px;
  background-color: #000102 ;
  border-radius: 50%;
  color: #fff;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}



/* 
---------------------------------------------
Especialidades
--------------------------------------------- 
*/

#areaas_legales {
  margin-top: 10px;
  margin-bottom: 10px;
}


#areaas_legales .section-heading{
  padding-right: 120px;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 25px; 
  font-size: 30px;
  font-weight: 900;
  color: #17203D;
}

#areaas_legales  p {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  line-height: 24px;
  font-size: 20px;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-lg-5 {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
}
@media (max-width: 767px){
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  #areaas_legales  p {
    text-align: left;
  }
}

#areaas_legales .section-heading {
  font-size: 2.25rem;
  font-weight: 700;
  margin-top: 3.75rem;
}

#areaas_legales .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
#areaas_legales .blockquote > :last-child {
  margin-bottom: 0;
}

#areaas_legales .blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
#areaas_legales .blockquote-footer::before {
  content: "— ";
}

#areaas_legales  .caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

#areaas_legales  .text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}



.parea{ 
  margin-top:136px;
  width:100%;
  height:12vh;
  min-height: 100px;
  max-height: 200px;;
  position: static;
  background: #f5f5f8fd;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


.parea .welcomeconoce .welco-conoce {
  margin-top: 80px;
  align-content: center;
  text-align: center;
  margin: auto;
  display: table;
}

.parea .welcomeconoce h1{
  text-align: center;
  align-items: center;
  font-size:68px;
  font-weight:700;
  color:#17203D;
  font-family:"Avenir";;
  line-height:1.2;
  text-transform:uppercase;
  display:block;
}

.parea h1 span {
  color: #A1D5C7;
}
@media only screen and (min-width: 992px) and (max-width: 1400px){
  .parea .welcomeconoce h1{
    font-size:32px;
    line-height:1.2;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
  .parea .welcomeconoce h1{
    font-size:28px;
    line-height:1.2;
  }
  .parea .welcomeconoce .welco-conoce {
    margin-top: 25px;
  }
}
@media (max-width: 767px){
  .parea .welcomeconoce h1{
    font-size:20px;
    line-height:1.3;
  }

}

/* List with bullets */
.list-bullets {
  list-style: none;
  margin-left: 3%;
}

.list-bullets li {
  display: flex;
  align-items: center;
  font-weight: 550;
  line-height: 24px;
  font-size: 20px;
}

.mb-la{
  display: flex;
  align-items: center;
  font-weight: 550;
  line-height: 24px;
  font-size: 10px;
}

.mb-lar{
  border: 0em;
  text-align: left;
  margin-top: 2%;
  margin-bottom: 1rem !important;
  }

@media only screen and (min-width: 992px) and (max-width: 1400px){
  .list-bullets li {
    display: flex;
    align-items: center;
    line-height: 24px;
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
  .list-bullets li {
    display: flex;
    align-items: center;
    line-height: 24px;
    font-size: 18px;
  }
}
@media (max-width: 767px){
  .list-bullets li {
    display: flex;
    align-items: center;
    line-height: 24px;
    font-size: 16px;
  }

}


.list-bullets li::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 45%;
  background: #A1D5C7;
  border: 2px solid #A1D5C7;
  display: block;
  margin-right: 1.5rem;
}


/* 
---------------------------------------------
Blog
--------------------------------------------- 
*/



.site-section {
  padding: 7em 0; 
  margin-top: 180px;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}


    .site-section h1{
      text-align: center;
      font-size:38px;
      font-weight:700;
      color:#17203D;
      font-family:"Avenir";;
      line-height:1.2;
      text-transform:uppercase;
      margin-bottom:30px;
      display:block;
    }
  
    .site-section h1 span {
      color: #A1D5C7;
    }

    .p-blog-2{
      font-size: 18px;
      font-weight:600;
      color:#17203D;
      font-family:"Avenir";;

    }

    @media only screen and (min-width: 992px) and (max-width: 1400px){
  
      .site-section h1{
        font-size:32px;
        line-height:1.2;
      }
    

    }
    @media only screen and (min-width: 768px) and (max-width: 991px){
      .site-section h1{
        font-size:28px;
        line-height:1.2;
      }
    }

    @media (max-width: 767px){
      .site-section h1{
        font-size:20px;
        line-height:1.3;
      }
    }

    .site-section p{
      text-align: center;
      font-size: 18px;
      line-height:30px;
      margin-bottom:20px;
      color:#17203D;
      margin-bottom:25px;
      padding-right:49px;
      font-family:"Avenir";;
      line-height:1.8;
    }


    @media only screen and (min-width: 992px) and (max-width: 1400px){
      .site-section p{
        padding-right:0px;
        font-size:16px;
      }
    }
    
    @media only screen and (min-width: 768px) and (max-width: 991px){
      .site-section p{
        padding-right:0px;
        font-size:15px;
      }
    }
    @media (max-width: 767px){
      .site-section p{
        padding-right:0px;
        font-size:14px;
      }

    }
  

    .blog-entries .blog-entry {
      display: block;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      margin-bottom: 30px;
      position: relative; }
      .blog-entries .blog-entry:hover, .blog-entries .blog-entry:focus {
        opacity: .7;
        top: -1px;
        -webkit-box-shadow: 0 3px 50px -2px rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 3px 50px -2px rgba(0, 0, 0, 0.2) !important; }
      .blog-entries .blog-entry .blog-content-body {
        padding: 20px;
        border: 1px solid #efefef;
        border-top: none; }
      .blog-entries .blog-entry img {
        max-width: 100%; }
      .blog-entries .blog-entry h2 {
        font-size: 18px;
        line-height: 1.5; }
      .blog-entries .blog-entry p {
        font-size: 13px;
        color: gray; }
    
    .blog-entries .post-meta {
      margin-bottom: 20px;
      font-size: 14px;
      color: #b3b3b3; }
      .blog-entries .post-meta .author img {
        width: 30px;
        border-radius: 50%;
        display: inline-block; }

        .post-entry-horzontal {
          margin-bottom: 30px; 
          }
          .post-entry-horzontal a {
            overflow: hidden;
            display: table;
            position: relative;
            width: 100%;
            top: 0;
            -webkit-transition: .1s all ease;
            -o-transition: .1s all ease;
            transition: .1s all ease; }
            .post-entry-horzontal a .image, .post-entry-horzontal a .text {
              display: table-cell;
              vertical-align: middle; }
              @media (max-width: 767.98px) {
                .post-entry-horzontal a .image, .post-entry-horzontal a .text {
                  display: block; } }
            .post-entry-horzontal a .image {
              width: 200px;
              background-size: cover;
              background-position: center center; }
              @media (max-width: 767.98px) {
                .post-entry-horzontal a .image {
                  width: 100%;
                  height: 200px; } }
            .post-entry-horzontal a .text {
              padding: 30px;
              width: calc(100 - 200px);
              border: 1px solid #e6e6e6;
              border-left: none; }
              @media (max-width: 767.98px) {
                .post-entry-horzontal a .text {
                  width: 100%;
                  height: 200px;
                  border: 1px solid #e6e6e6;
                  border-top: none; } }
              .post-entry-horzontal a .text h2 {
                font-size: 20px; }
              .post-entry-horzontal a .text p {
                color: #999999;
                margin-bottom: 30px; }
            .post-entry-horzontal a:hover {
              top: -3px;
              -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
              box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1); }

 .mr-2{
  margin-left: 14px;
  margin-right: 14px;
}

.mr-3{
  margin-left: 14px;
  margin-right: -6px;
}

            .post-meta img {
              margin-right: 12px;
            }

            .post-meta span {
              font-size: 16px;
              font-weight:600;
              font-family:"Avenir";;
            }

            @media only screen and (min-width: 992px) and (max-width: 1400px){
              .post-meta span {
                font-size: 15px;
                font-weight:600;
                font-family:"Avenir";;
              }
            }
            
            @media only screen and (min-width: 768px) and (max-width: 991px){
              .post-meta span {
                font-size: 14px;
                font-weight:600;
                font-family:"Avenir";;
              }
            }
            @media (max-width: 767px){
              .post-meta span {
                font-size: 12px;
                font-weight:600;
                font-family:"Avenir";;
              }
            }


            .post-h2 {
              font-size: 20px;
              color:#17203D;
              font-weight:600;
              font-family:"Avenir";;
            }

            @media only screen and (min-width: 992px) and (max-width: 1400px){
              .post-h2{
                font-size: 18px;
                color:#17203D;
                font-weight:600;
                font-family:"Avenir";;
              }
            }
            
            @media only screen and (min-width: 768px) and (max-width: 991px){
              .post-h2{
                font-size: 16px;
                color:#17203D;
                font-weight:600;
                font-family:"Avenir";;
              }
            }
            @media (max-width: 767px){
              .post-h2 {
                font-size: 14px;
                color:#17203D;
                font-weight:600;
                font-family:"Avenir";;
              }
            }

            .sidebar-box {
              margin-bottom: 4em;
              font-size: 15px;
              width: 100%;
              float: left;
              background: #fff; }
              .sidebar-box *:last-child {
                margin-bottom: 0; }
              .sidebar-box .heading {
                font-size: 18px;
                margin-bottom: 30px;
                padding-bottom: 20px;
                border-bottom: 1px solid #e6e6e6; }
            
                .bio {
                  padding: 15px;
                  align-content: center;
                  text-align: center;
                  background: #fff;
                  border: 1px solid #e6e6e6;
                  font-weight: 400; }
                  .bio img {
                    border: 4px solid #fff;
                    -webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.4);
                    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.4);
                    max-width: 100px;
                    margin-top: -4em;
                    border-radius: 50%;
                    position: relative;
                    margin-bottom: 20px; }
                  .bio h2 {
                    font-size: 20px;
                    text-align: center; 
                    margin-bottom: 12px;}
                  .bio .bio-body .social a {
                    color: #17203D; }
                  .bio .bio-body .social a:hover {
                      color: #A1D5C7;
                    }

                    .post-entry-sidebar .post-meta {
                      font-size: 14px;
                      color: #b3b3b3; }
                    
                    .post-entry-sidebar ul {
                      padding: 0;
                      margin: 0; }
                      .post-entry-sidebar ul li {
                        list-style: none;
                        padding: 0 0 20px 0;
                        margin: 0 0 20px 0; }
                        .post-entry-sidebar ul li a {
                          display: table; }
                          .post-entry-sidebar ul li a img {
                            width: 90px; }
                          .post-entry-sidebar ul li a img, .post-entry-sidebar ul li a .text {
                            display: table-cell;
                            vertical-align: middle; }
                          .post-entry-sidebar ul li a .text h4 {
                            font-size: 18px; }

  .post-h4 {
   font-size: 18px;
   margin-left: 8px;
   color:#17203D;
   font-weight:600;
   font-family:"Avenir";;
}
                
@media only screen and (min-width: 992px) and (max-width: 1400px){
  .post-h4{
    font-size: 16px;
    color:#17203D;
    font-weight:600;
    font-family:"Avenir";;
 }
}
                            
@media only screen and (min-width: 768px) and (max-width: 991px){
  .post-h4{
    font-size: 14px;
    color:#17203D;
    font-weight:600;
    font-family:"Avenir";;
  }
}
@media (max-width: 767px){
 .post-h4 {
    font-size: 14px;
    color:#17203D;
    font-weight:600;
    font-family:"Avenir";;
  }
}

.bio-body{
  display:block;
  text-align: center;
  align-content: center;
}
@media only screen and (min-width: 1400px){
  .bio-body p {
    text-align: center;
    margin-right: -40px;
  }
}


/* 
---------------------------------------------
POST
--------------------------------------------- 
*/




.parea-blog{ 
  margin-top:210px;
  width:100%;
  position: static;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}




.parea-blog .blog-post .post {
  margin-top: 80px;
  align-content: center;
  text-align: center;
  margin: auto;
  display: table;
}

.parea-blog .blog-post h1{
  text-align: center;
  align-items: center;
  font-size:38px;
  font-weight:700;
  color:#17203D;
  font-family:"Avenir";;
  line-height:1.2;
  display:block;
  margin-bottom: 30px;
  padding-right: 20rem !important;
    padding-left: 20rem !important;
}

.parea-blog .blog-post .author img {
  border: 2px solid #fff;
  -webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.4);
  max-width: 50px;
  border-radius: 50%;
  align-content: center;
  margin-bottom: 8px;
  margin-right: 2px; } 

.parea-blog h1 span {
  color: #A1D5C7;
}
@media only screen and (min-width: 992px) and (max-width: 1400px){
  .parea-blog .blog-post h1{
    font-size:28px;
    line-height:1.2;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
  .parea-blog .blog-post h1{
    font-size:24px;
    line-height:1.2;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .parea-blog .blog-post .post {
    margin-top: 25px;
  }
}
@media (max-width: 767px){
  .parea-blog .blog-post h1{
    font-size:22px;
    line-height:1.3;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

}

.blog-post .post {
  margin-bottom: 20px;
  font-size: 20px;
  color: #b3b3b3; }

  .post  span {
    font-size: 20px;
    font-weight:600;
    font-family:"Avenir";;
  }

  @media only screen and (min-width: 992px) and (max-width: 1400px){
    .post  span {
      font-size: 18px;
      font-weight:600;
      font-family:"Avenir";;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px){
    .post  span {
      font-size: 16px;
      font-weight:600;
      font-family:"Avenir";;
    }
  }
  @media (max-width: 767px){
    .post  span {
      font-size: 14px;
      font-weight:600;
      font-family:"Avenir";;
    }
  }




#post-area {
  margin-top: 30px;
  margin-bottom: 2vh;
  width: 100%;
}


#post-area .section-heading{
  padding-right: 120px;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 25px; 
  font-size: 30px;
  font-weight: 900;
  color: #17203D;
}

#post-area  p {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  line-height: 24px;
  font-size: 20px;
}

#post-area  .px-4 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

#post-area  .px-lg-5 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  #post-area  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
}
@media (max-width: 767px){
  #post-area  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  #post-area  p {
    text-align: left;
  }
}

#post-area .section-heading {
  font-size: 2.25rem;
  font-weight: 700;
  margin-top: 3.75rem;
}

#post-area .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
#post-area .blockquote > :last-child {
  margin-bottom: 0;
}

#post-area .blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
#post-area .blockquote-footer::before {
  content: "— ";
}

#post-area  .caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

#post-area  .text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;

}


.intro-post{
  margin-bottom: 45px;
  font-weight: 480;
  font-size: 25px;
  color: #23283a;
  font-style: italic;
  text-align: center;
}

@media (max-width: 767px){
  .intro-post {
    font-size: 18px;
  }
}

.p-curs{
  font-style: italic;
}


.p-cur-os{
  font-style: italic;
  font-weight: 780;
}

.p-bold{
  font-weight: 780;
}

.p-bold_su{
  font-weight: 780;
  text-decoration: underline;
}
.p-subr{
  text-decoration: underline;
}

/* 
---------------------------------------------
Lazy
--------------------------------------------- 
*/

.suspense {
    background-color: #fff;
    left: 0;
    right: 0;
    top: 10%;
    bottom: 0;
    position: fixed;
    z-index: 99999;
    color: #fff;
}




.section-title-2 {
  text-align: center;
  padding-bottom: 30px;
}

.section-title-2 h1 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #A1D5C7;
  color: #17203D;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title-2 h2 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
}

.section-title-2 h2 span {
  color: #A1D5C7;
}

.section-title-2 p {
  margin: 15px auto 0 auto;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .section-title-2 p {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1400px){
  .section-title-2 h2 {
    margin: 15px 0 0 0;
    font-size: 30px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .section-title-2 h2 {
    margin: 15px 0 0 0;
    font-size: 28px;
    font-weight: 700;
  }
}

@media (max-width: 767px){
  .section-title-2 h2 {
    margin: 15px 0 0 0;
    font-size: 20px;
    font-weight: 700;
  }
}
